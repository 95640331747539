.container {
  display: grid;
  grid-template-columns: .38fr .52fr;
  grid-column-gap: var(--space-xxxl);
}

.carlineGroupsWrapper {
  order: -1;
}

.carlineGroupContainer + .carlineGroupContainer {
  margin-top: var(--space-xxxl);
}

.list {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.listItem {
  border-top: 1px solid var(--color-grey-40);
  border-bottom: 1px solid var(--color-grey-40);
}

.listItem + .listItem {
  border-top-width: 0;
}

.carlineContainer {
  display: flex;
  align-items: center;
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.image,
.placeholderImage {
  margin-right: var(--space-m);
  margin-bottom: var(--space-s);
}

svg.placeholderImage {
  width: 100px;
}

.text {
  font-family: var(--audi-type-wide);
  font-weight: 200;
  color: var(--color-grey-80);
  text-align: left;
}
