.combinedKey {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.td {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
}

.orderKey {
  word-break: break-all;
}
