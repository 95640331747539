.title {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-m);
}

.fallbackLink {
  font-weight: bold;
}

.nameCol,
.priceCol,
.optionCol {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
}

.nameCol {
  width: 100%;
}

.nameContainer {
  margin-bottom: var(--space-s);
}

@media screen and (min-width: 769px) {
  .nameColInner {
    display: flex;
    justify-content: space-between;
  }

  .nameContainer {
    margin-bottom: 0;
  }
}

.evaKey {
  margin-left: var(--space-s);
  color: var(--color-grey-50);
  font-family: var(--audi-type-wide);
  font-weight: 200;
  font-size: .75em;
}

.priceCol {
  text-align: right;
  padding-left: var(--space-s);
  white-space: nowrap;
}

.optionCol {
  --col-horizontal-padding: var(--space-m);
  --icon-size: var(--icon-size-small);
  --button-horizontal-padding: var(--space-xs);
  --button-width: calc(var(--icon-size) + var(--button-horizontal-padding) * 2);
  --num-of-buttons: 2;

  min-width: calc(var(--button-width) * var(--num-of-buttons) + var(--col-horizontal-padding) * 2);
  text-align: center;
}

.optionsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.optionBtn {
  padding-right: var(--button-horizontal-padding);
  padding-left: var(--button-horizontal-padding);
  border-width: 0;
  background-color: transparent;
}

.icon {
  width: var(--icon-size);
}
