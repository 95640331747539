.nameCol,
.attributeCol,
.priceCol {
  padding: var(--space-m) var(--space-s);
}

.nameCol {
  padding-left: 0;
  width: 100%;
}

.nameColInner {
  display: grid;
  grid-column-gap: var(--space-m);
  grid-template-columns: auto 1fr;
}

.label {
  cursor: pointer;
}

.mbvId {
  margin-left: var(--space-xs);
  color: var(--color-grey-50);
  font-family: var(--audi-type-wide);
  font-weight: 200;
  font-size: .75em;
  text-align: right;
  cursor: pointer;
}

.subtext {
  color: var(--color-grey-40);
  font-size: 12px;
  line-height: 1.5;
  margin-top: var(--space-s);
  margin-bottom: 0;
  white-space: pre-line;
}

.displayId,
.powers {
  font-family: var(--audi-type-wide);
  font-weight: 200;
}

.priceCol {
  padding-right: 0;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.priceContainer {
  margin-bottom: var(--space-s);
}
