.linkButton {
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border: none;
  padding: var(--space-s) var(--space-m);
  cursor: pointer;
}

.container {
  margin-top: var(--space-xxxl);
  width: 100%;
  display: flex;
  justify-content: center;
}
