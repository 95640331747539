.nameCol,
.priceCol {
  padding: var(--space-s) 0;
}

.nameCol {
  width: 100%;
  padding-right: var(--space-s);
}

.nameColInner {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: var(--space-m) var(--space-s);
}

.infoButtonWrapper {
  grid-column-start: 2;
}

@media screen and (min-width: 769px) {
  .nameColInner {
    grid-template-columns: auto 1fr 100px;
    grid-template-rows: initial;
  }

  .infoButtonWrapper {
    grid-column-start: initial;
  }
}

.label[for] {
  cursor: pointer;
}

.detailedLabel {
  display: flex;
}

.labelIsConflictual {
  color: var(--color-grey-40);
}

.labelIsReplaced b {
  text-decoration: line-through;
}

.checkbox[disabled] {
  border-width: 0;
}

.displayId {
  margin-left: var(--space-xs);
  color: var(--color-grey-50);
  font-family: var(--audi-type-wide);
  font-weight: 200;
  font-size: .75em;
  text-align: right;
}

.displayIdDetailed {
  margin-left: auto;
}

.priceCol {
  padding-left: var(--space-m);
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.priceContainer {
  margin-bottom: var(--space-s);
}

.lockIcon {
  margin-right: var(--space-xs);
}

.recommendedNotSelected {
  color: var(--color-red);
  margin-top: var(--space-xxs);
  font-size: .75rem;
}

.detailedList {
  margin: 0;
}

.detailedName {
  display: flex;
  align-items: flex-start;
}

.detailedName dt {
  width: 150px;
  flex-shrink: 0;
  margin-right: 5px;
}

.detailedName dd {
  margin: 0;
  padding-left: 10px;
  flex-grow: 1;
}

.detailContainer {
  display: flex;
}

@media screen and (max-width: 1300px) {
  .detailedLabel {
    flex-direction: column;
    gap: 10px;
  }

  .detailContainer {
    flex-direction: column;
    gap: 10px;
  }

  .displayIdDetailed {
    margin: 0;
  }
}
