.modalOpen {
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
}

@keyframes fade-in-background {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, .5);
  }
}

.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 3;
  animation-name: fade-in-background;
  animation-duration: var(--time-xl);
  overflow: auto;
}

.content {
  position: relative;
  max-height: 100%;
  background-color: var(--color-white);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  display: block;
  height: var(--icon-size-large);
  width: var(--icon-size-large);
  background-color: var(--color-white);
  border-width: 0;
  z-index: 5;
}

.toggleButton {
  display: block;
}
