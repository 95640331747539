.footer {
  padding: var(--space-xl) var(--space-column);
}

.disclaimerLinkList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.disclaimerLinkListItem {
  margin-top: var(--space-m);
  margin-right: var(--space-m);
  margin-bottom: var(--space-m);
}
