.container {
  display: grid;
  grid-template-columns: 1fr .6fr;
  grid-column-gap: var(--space-m);
  position: sticky;
  bottom: 0;
  margin-top: var(--space-xxl);
  padding: var(--space-m);
  background-color: var(--color-grey-80);
  color: var(--color-white);
  z-index: 1;
}

.title {
  font-size: 1em;
  font-weight: bold;
  font-family: var(--audi-type-wide);
  line-height: 1.5;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin-top: 0;
  margin-bottom: 0;
}

.imagesContainer {
  grid-column: 2 / -1;
  grid-row: 1 / 3;
  margin-bottom: var(--space-m);
}

.linkList {
  list-style: none;
  font-size: .875em;
  display: grid;
  margin: 0;
  padding-left: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--space-l) var(--space-s);
  grid-column: 1 / 2;
  grid-row: 3 / 3;
  text-align: center;
  align-items: center;
}

.linkList li:empty {
  display: none;
}

.navButtonContainer {
  grid-column: 2 / -1;
  grid-row: 3 / 3;
}

.navButton {
  display: inline-block;
  width: 100%;
  padding: var(--space-m);
  background-color: var(--color-white);
  color: var(--color-black);
  text-align: center;
}

.linkList,
.navButton {
  font-size: .875em;
}

@media screen and (min-width: 1025px) {
  .container {
    display: block;
    background-color: var(--color-white);
    color: var(--primary-font-color);
    padding: 0;
    top: var(--space-xxl);
    border: 1px solid var(--color-grey-50);
    bottom: initial;
    flex-basis: 26%;
    flex-shrink: 0;
    margin-right: var(--space-column);
  }

  .title {
    margin: var(--space-s) var(--space-m);
  }

  .imagesContainer {
    margin-bottom: 0;
  }

  .pricesContainer {
    margin: var(--space-s) var(--space-m);
  }

  .navButtonContainer {
    margin: var(--space-l) var(--space-m) var(--space-xl);
  }

  .navButton {
    background-color: var(--primary-button-bg-color);
    color: var(--primary-button-color);
  }

  .linkList {
    margin: var(--space-m);
    margin-bottom: var(--space-l);
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
}
