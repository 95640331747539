.row {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
  font-size: .875em;
  flex-wrap: wrap;
}

.priceTitle {
  padding-right: var(--space-m);
}

.price {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}

.rowSum {
  font-weight: bold;
  margin-top: var(--space-s);
  padding-top: var(--space-s);
  border-top: 1px solid var(--color-grey-50);
}
