.container {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: var(--space-s);
  margin-bottom: var(--space-xl);
  border: 1px solid var(--color-grey-10);
}

.price {
  font-weight: bold;
}
