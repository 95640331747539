.col {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
  word-break: break-all;
}

.valueCol {
  text-align: right;
  white-space: nowrap;
}

.figure {
  width: 100%;
  margin-bottom: 0;
}
