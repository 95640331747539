.container {
  display: block;
  text-align: right;
  margin-bottom: var(--space-m);
}

.button {
  background-color: transparent;
  color: var(--color-grey-40);
  border-color: var(--color-grey-40);
  border-style: solid;
  border-width: 0 0 1px;
  padding-bottom: 2px;
  font-size: 14px;
}

.button[disabled] {
  color: var(--color-white);
  border-color: var(--color-white);
  border-bottom-width: 2px;
  padding-bottom: 1px;
}

@media screen and (min-width: 1025px) {
  .button {
    color: var(--color-grey-50);
    border-color: var(--color-grey-50);
  }

  .button[disabled] {
    color: var(--color-black);
    border-color: var(--color-black);
  }
}
