.container {
  padding-right: var(--space-m);
  padding-left: var(--space-m);
  margin-top: var(--space-m);
  margin-bottom: var(--space-m);
  list-style: none;
}

.headline {
  font-weight: var(--font-weight-bold);
  margin-top: var(--space-s);
  margin-bottom: var(--space-xs);
}

.blockEntry {
  display: flex;
  margin: 0;
}

.entryKey,
.entryValue {
  flex-grow: 1;
  font-size: .875em;
}

.entryValue {
  text-align: right;
  white-space: nowrap;
}
