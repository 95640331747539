.spinnerWrapper {
  display: flex;
  justify-content: center;
}

.matrixContainer {
  margin-bottom: var(--space-xl);
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-width: 0;
  background-color: transparent;
  padding-left: var(--space-m);
  text-align: left;
  border-top: 1px solid var(--color-grey-10);
  border-bottom: 1px solid var(--color-grey-10);
  font-weight: bold;
}

.toggleButtonActive {
  background-color: var(--color-grey-10);
}

.ratesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--space-m);
}

.rateButton {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--space-s);
  padding: var(--space-m);
  cursor: pointer;
}

.submitButtonWrapper {
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  padding: var(--space-m);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border-width: 0;
}

.submitButton[disabled] {
  opacity: .5;
}
