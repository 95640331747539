.container {
  overflow: hidden;
  padding-bottom: 1px;
}

.label {
  display: block;
  font-size: .875em;
  color: var(--label-font-color);
}
