.button,
.buttonPlaceholder {
  padding: 0;
  background-color: var(--color-white);
  border-style: solid;
  border-width: 0 0 5px;
  border-color: transparent;
  transition: border-color .2s;
  position: relative;
}

.buttonOpened,
.button:hover {
  border-bottom-color: var(--color-grey-60);
}

.img,
.imgPlaceholder {
  display: block;
  margin-bottom: var(--space-l);
}

.titlePlaceholder,
.title {
  display: inline-block;
  width: 100%;
  border-top: 1px solid var(--color-grey-40);
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
  text-align: left;
  font-family: var(--audi-type-extended);
}

.titlePlaceholder {
  color: var(--color-grey-20);
  border-top: 1px solid var(--color-grey-20);
}
