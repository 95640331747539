.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.linkList,
.linkListLast {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-bottom: var(--space-l);
  list-style: none;
  overflow: hidden;
}

.navigationLogo {
  display: block;
  margin-bottom: 10px;
  margin-left: 5px;
}

.inlineText {
  display: inline-flex;
  justify-content: space-between;
  justify-items: flex-start;
}

.navigationLogo h2 {
  margin: 0;
}

.inlineText span {
  display: inline-block;
}

.inlineText span:first-child {
  color: red;
  margin-right: 3px;
}

.linkListLast {
  margin-top: auto;
}

.listItem {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.link {
  color: var(--sidebar-link-color);
}

.link,
.linkDisabled {
  display: flex;
  align-items: center;
  padding: var(--space-xs) var(--space-s);
  flex-wrap: nowrap;
}

.linkActive {
  transition: background-color .3s ease-in-out;
  background-color: var(--sidebar-link-active-bg-color);
  color: var(--sidebar-link-active-color);
}

.link,
.linkActive,
.linkDisabled {
  transition: padding-left var(--time-xl);
}

.linkDisabled {
  color: var(--sidebar-link-disabled-color);
}

:global(.sidebar-expanded) .link,
:global(.sidebar-expanded) .linkActive,
:global(.sidebar-expanded) .linkDisabled {
  padding-left: var(--space-xxl);
}

@media screen and (min-width: 1281px) {
  .link,
  .linkActive,
  .linkDisabled {
    padding-left: var(--space-xxl);
  }
}

.link:hover {
  color: var(--sidebar-link-hover-color);
}

.icon {
  height: var(--icon-size-small);
  width: var(--icon-size-small);
  flex-shrink: 0;
  flex-grow: 0;
}

.trafficLight {
  width: var(--navigation-traffic-light-width);
  height: 1.5rem;
  margin-left: auto;
}

.trafficLightIncomplete {
  background-color: var(--sidebar-traffic-light-incomplete-color);
}

.trafficLightError {
  background-color: var(--sidebar-traffic-light-error-color);
}

.trafficLightDone {
  background-color: var(--sidebar-traffic-light-done-color);
}

.label,
.labelActive {
  display: flex;
  padding-left: var(--space-s);
  flex-grow: 1;
  overflow: hidden;
}

@keyframes close-sidebar {
  from {
    max-width: 0;
    display: none;
    margin: 0;
    max-height: 1.5rem;
    white-space: nowrap;
    opacity: 0;
  }

  to {
    max-height: unset;
    white-space: nowrap;
    opacity: 1;
  }
}

@media screen and (max-width: 1280px) {
  :global(.sidebar-collapsed) .label,
  :global(.sidebar-collapsed) .labelActive {
    max-width: 0;
    display: none;
    padding: 0;
    margin: 0;
    max-height: 1.5rem;
    opacity: 0;
  }

  :global(.sidebar-collapsed) .navigationLogo {
    display: none;
  }

  :global(.sidebar-expanded) .label,
  :global(.sidebar-expanded) .labelActive,
  :global(.sidebar-expanded) .navigationLogo {
    animation-name: close-sidebar;
    animation-duration: var(--time-xl);
  }
}
