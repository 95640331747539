.container {
  position: fixed;
  top: calc(var(--space-m) - var(--space-xxs));
  right: var(--space-m);
  z-index: 5;
}

.list {
  width: 18.5rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.listItem {
  margin-top: var(--space-xxs);
}

.wrapper {
  display: flex;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .1), 0 0 40px 0 rgba(0, 0, 0, .15);
  animation: fade-in;
  animation-fill-mode: forwards;
  animation-duration: var(--time-xl);
  animation-timing-function: var(--transition-ease-in-out);
  opacity: 0;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.listItemClosing .wrapper {
  animation: fade-out;
  animation-fill-mode: forwards;
  animation-duration: var(--time-xl);
  opacity: 1;
}

@keyframes shrink-height {
  from {
    max-height: 300px;
    margin-top: var(--space-xxs);
  }

  to {
    max-height: 0;
    margin-top: 0;
  }
}

.listItemClosing {
  animation: shrink-height;
  animation-fill-mode: forwards;
  animation-delay: calc(var(--time-xl) + var(--time-xs));
  animation-duration: var(--time-xl);
  animation-timing-function: var(--transition-ease-in-out);
  margin-top: var(--space-xxs);
}

.wrapper-error {
  background-color: var(--color-red);
  color: var(--color-white);
}

.wrapper-info {
  background-color: var(--color-green);
  color: var(--color-white);
}

.message {
  flex-grow: 1;
  align-self: center;
  padding: .75rem;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.button {
  align-self: flex-start;
  border-width: 0;
  padding: 0;
  background-color: transparent;
}

.icon {
  display: block;
  color: var(--color-white);
}
