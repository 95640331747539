.container {
  max-width: 950px;
  padding: var(--space-xl);
  line-height: 1.5;
}

.title {
  margin-top: 0;
  margin-bottom: var(--space-xl);
  text-align: center;
}

.buttonList {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2em;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  font-weight: bold;
  margin-top: var(--space-xl);
}

.button {
  padding: var(--space-m);
  background-color: var(--color-black);
  color: var(--color-white);
  border-width: 0;
}
