.subTitle {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.winterTyreAnchor {
  display: block;
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  border-width: 0;
  padding: var(--space-s) var(--space-m);
  width: 25%;
  text-align: center;
  margin-left: auto;
  word-wrap: break-word;
}
