.container {
  max-width: 900px;
  min-height: 300px;
  padding: 0 var(--space-xxl);
  margin: var(--icon-size-large) 0 var(--space-xxl);
  position: relative;
  overflow-y: auto;
  text-align: left;
}

.buttonBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  position: relative;
  margin-top: var(--space-xl);
}

.modelChooseButton {
  display: inline-block;
  width: 100%;
  border: none;
  padding: var(--space-s) var(--space-m);
  background-color: var(--primary-button-bg-color);
  color: var(--primary-button-color);
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
}

.modelChooseButton + .modelChooseButton {
  margin-left: var(--space-s);
}
