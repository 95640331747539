*,
::before,
::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-y: scroll;
}

body {
  margin: 0;
  color: var(--primary-font-color);
  font-family: var(--primary-font-family);

  --sidebar-open-width: 300px;
  --sidebar-closed-width: calc(var(--space-s) + var(--icon-size-small) + var(--space-s) + var(--navigation-traffic-light-width) + var(--space-s));
  --navigation-traffic-light-width: 10px;
}

h1,
h2,
h3,
h4 {
  font-family: var(--secondary-font-family);
  font-weight: normal;
}

h1 {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
  font-size: 2.375rem;
}

a {
  text-decoration: none;
  color: var(--link-color);
}

button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

button::-moz-focus-inner {
  border-width: 0;
}

a:focus,
button:focus {
  outline: 0;
}

a:focus-visible,
button:focus-visible {
  outline: 1px dotted var(--input-border-focus-color);
  outline-offset: 1px;
}

hr {
  border-style: solid solid none;
  border-color: var(--color-grey-80);
}

:global(.contentWithCartbox) {
  min-height: 100vh;
  padding-right: var(--space-column);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-column);
}

@media screen and (min-width: 1025px) {
  :global(.contentWithCartboxContainer) {
    display: flex;
    align-items: flex-start;
  }

  :global(.contentWithCartbox) {
    flex-grow: 1;
  }
}

:global(.filterBar) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  position: sticky;
  background: white;
  top: 0;
  z-index: 1;
  padding-bottom: var(--space-s);
  padding-top: var(--space-l);
}

:global(.anchor) {
  --filterbar-height: calc(-1 * (var(--space-l) + 57px + var(--space-s)));

  top: var(--filterbar-height);
  left: calc(-1 * (var(--sidebar-closed-width) + var(--space-xxl)));
  visibility: hidden;
  position: relative;
}

body svg {
  background-color: transparent;
}
