.row {
  display: flex;
  justify-content: space-between;
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
  font-size: .875em;
  flex-wrap: wrap;
}

.title {
  padding-right: var(--space-m);
}

.value {
  text-align: right;
  white-space: nowrap;
  flex-grow: 1;
}
