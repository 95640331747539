@keyframes loading {
  from {
    opacity: 0;
  }

  to {
    opacity: .5;
  }
}

.loading {
  position: relative;
  pointer-events: none;
}

.loading::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--isLoadingBgColor);
  opacity: .5;
  z-index: 1;
  animation-name: loading;
  animation-duration: var(--time-xxl);
  animation-timing-function: var(--transition-ease-in-out);
  animation-iteration-count: initial;
  animation-direction: normal;
  transition-property: opacity;
}
