.icon {
  max-height: 20px;
  margin-left: 3px;
  stroke-width: 2;
}

.title {
  cursor: pointer;
  text-decoration: none;
}

.title:hover {
  text-decoration: underline;
}
