.container {
  padding-right: var(--space-column);
  padding-bottom: var(--space-xxl);
  padding-left: var(--space-column);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xl);
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.optionList {
  display: flex;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.optionListItem + .optionListItem {
  margin-left: var(--space-m);
}

.loadingIndicator::before {
  width: calc(100% + var(--space-column) * 2) !important;
  left: calc((var(--space-column)) * -1);
}

@media screen and (max-width: 1100px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
