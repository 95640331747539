.wrapper {
  padding-top: var(--space-xxl);
  padding-right: var(--space-s);
  padding-left: var(--space-xxl);
}

.iconButtonWrapper {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
}

.wrapper-audi {
  padding-bottom: var(--space-xl);
}

.wrapper-vw {
  padding-bottom: var(--space-m);
}

.wrapper-vwn {
  padding-bottom: var(--space-m);
}

.wrapper-man {
  padding-bottom: var(--space-m);
}

.hidden {
  opacity: 0;
}

@media screen and (max-width: 1280px) {
  :global(.sidebar-collapsed) .wrapper {
    padding-left: var(--space-s);
  }
}

.icon {
  display: block;
  max-width: 100%;
}

.icon-audi {
  height: 36px;
}

.icon-vwn {
  height: 100px;
}
