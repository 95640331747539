.container {
  background-color: var(--color-grey-10);
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 1em;
  margin-bottom: 2px;
  padding: var(--space-s);
}

.containerWithCheckbox {
  grid-template-columns: auto 1fr auto auto;
}

.label {
  font-weight: bold;
}

.price {
  font-weight: bold;
}

.evaKey {
  color: var(--color-grey-50);
  font-weight: 200;
  font-size: .75em;
}

.priceAndInfoContainer {
  display: flex;
  flex-direction: column;
}

.infoElement {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
