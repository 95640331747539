.container {
  max-width: 900px;
  padding: var(--space-xl);
}

@media only screen and (min-width: 900px) {
  .container {
    width: 900px;
  }
}

.title {
  margin-top: 0;
  font-size: 2em;
}
